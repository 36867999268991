import {useEffect, useContext, useState} from "react";
import { useQueryParam, StringParam } from "use-query-params";
import { navigate } from "gatsby";
import UserProfileContext from "../context/user-profile-context";
import GameContext from "../context/game-context";

export default function useSignUpGuard() {
  const [gameIdFromParams] = useQueryParam("gameId", StringParam);
  const { hasAuth, userProfile, isLoading } = useContext(UserProfileContext);
  const { game, isLoadingGame } = useContext(GameContext);
  const [hasFinishedCheck, setHasFinishedCheck] = useState(false);

  const navigateToRouteAfterLogout = async () => {
    const gameId = gameIdFromParams || game?.id;
    let url = '/game/sign-up';
    if (gameId) {
      const params = new URLSearchParams({gameId: gameId});
      url += `?${params.toString()}`;
    }
    await navigate(url);
  }

  useEffect(() => {
    if (isLoading || isLoadingGame) {
      return;
    }

    if (hasAuth === false || !userProfile?.name) {
      navigateToRouteAfterLogout();
    }

    setHasFinishedCheck(true);
  });

  return {hasFinishedCheck, navigateToRouteAfterLogout};
}
