import React, {useContext} from "react";
import PropTypes from "prop-types";
import UserProfileContext from "../../../../context/user-profile-context";
import {Link} from "../../../../components/components";
import appContent from "../../../../markdown/app-content";

const LogoutMessage = ({onLogout, ...rest}) => {
  const {userProfile} = useContext(UserProfileContext);

  if (!userProfile) {
    return null;
  } else {
    return <div {...rest}>
      <p>
        {appContent.inGame.joinGame.loginMessage(userProfile?.name).prefix}
        <Link onClick={onLogout}>{appContent.inGame.joinGame.loginMessage(userProfile?.name).linkText}</Link>
        {appContent.inGame.joinGame.loginMessage(userProfile?.name).suffix}
      </p>
    </div>
  }
};

LogoutMessage.propTypes = {
  onLogout: PropTypes.func.isRequired,
}

export default LogoutMessage;
