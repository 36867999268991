import {StringParam, useQueryParam} from "use-query-params";
import {nullishStringsShouldBeNull} from "../utilities/helper-functions";
import {useEffect, useState} from "react";
import {GET_ALL_GAMES_QUERIES, getAllGames, getGame} from "../services/firebase";
import {first} from "rxjs/operators";
import {GameOptions} from "../entities/game";

export default function useGameIdFromURLOrPrimaryGame() {
  const [gameIdFromUrl] = useQueryParam("gameId", StringParam);
  const [gameId, setGameId] = useState(null);
  const [isPrimaryGame, setIsPrimaryGame] = useState(false);

  useEffect(() => {
    let afterCleanUp = false;

    if (nullishStringsShouldBeNull(gameIdFromUrl)) {
      setGameId(gameIdFromUrl);
    } else {
      getAllGames([GET_ALL_GAMES_QUERIES.PRIMARY_GAME])
        .pipe(first()).toPromise()
        .then(games => {
          const primaryGameId = games.length > 0 ? games[0].id : null;
          if (primaryGameId && !afterCleanUp) {
            setGameId(primaryGameId);
          }
        });
    }

    return () => {
      afterCleanUp = true;
    };
  }, [gameIdFromUrl]);

  useEffect(() => {
    if (gameId) {
      getGame(gameId).subscribe((game) => {
        setIsPrimaryGame(game?.getOption(GameOptions.IS_PRIMARY));
      });
    } else {
      setIsPrimaryGame(false)
    }
  }, [gameId])

  return {
    gameId,
    isPrimaryGame,
  };
}
