import React, {useContext, useState} from "react";
import {navigate} from "gatsby";
import {Button} from "react-bootstrap";
import {
  MarkdownPageLayout,
  TeamNameModal,
  JoinTeamModal,
  InviteInstructionsModal,
  LoadingSpinner,
} from "../../components/components";
import useIsLoading from "../../hooks/useIsLoading";
import UserProfileContext from "../../context/user-profile-context";
import useSignUpGuard from "../../hooks/useSignUpGuard";
import {AlertType} from "../../components/providers/app-alerts/AppAlerts";
import {AppAlertsContext} from "../../context/app-alerts-context";
import {createTeam, joinTeam} from "../../services/firestore/teams";
import LogoutMessage from "../../app/game/shared/logout-message/LogoutMessage";
import useNotifyOnNewTeamMember from "../../hooks/useNotifyOnNewTeamMember";
import {sleep} from "../../utilities/helper-functions";
import useGameIdFromURLOrPrimaryGame from "../../hooks/useGameIdFromURLOrPrimaryGame";
import appContent from "../../markdown/app-content";
import ApplyCustomGameTheme from "../../app/game/shared/apply-custom-game-theme/ApplyCustomGameTheme";
import {authLogout} from "../../services/firebase";
import GameContext from "../../context/game-context";

const JoinGamePage = () => {
  const [showCreateTeam, setShowCreateTeam] = useState(false);
  const [showJoinTeam, setShowJoinTeam] = useState(false);
  const [showInviteInstructions, setShowInviteInstructions] = useState(false);
  const [createdGameInviteCode, setCreatedGameInviteCode] = useState(null);
  const {isLoading, setIsLoading} = useIsLoading(false);
  const {userProfile} = useContext(UserProfileContext);
  const {popAlert, popError} = useContext(AppAlertsContext);
  const {gameId, isPrimaryGame} = useGameIdFromURLOrPrimaryGame();
  const {hasFinishedCheck, navigateToRouteAfterLogout} = useSignUpGuard();
  const {refreshQuery} = useContext(GameContext);
  useNotifyOnNewTeamMember();

  const showCreateTeamButton = isPrimaryGame;

  const navigateToGame = (goToGameId) => {
    const params = new URLSearchParams({gameId: goToGameId});
    navigate(`/game/waiting-area?${params.toString()}`);
  };

  const handleCreateTeam = async (teamName) => {
    setIsLoading(true);
    try {
      const inviteCode = await createTeam({
        gameId,
        userProfile,
        teamName,
      });
      refreshQuery();
      setShowCreateTeam(false);
      setCreatedGameInviteCode(inviteCode);
      setShowInviteInstructions(true);
    } catch (error) {
      popAlert(error.message, AlertType.ERROR);
    }
    setIsLoading(false);
  };

  const handleJoinTeam = async (phrase) => {
    setIsLoading(true);
    try {
      const {gameId: joinedGameId} = await joinTeam(userProfile, phrase);
      await sleep(1000);
      setShowJoinTeam(false);
      navigateToGame(joinedGameId);
    } catch (error) {
      alert(error.message);
    }
    setIsLoading(false);
  };

  const handleLogout = async () => {
    try {
      await navigateToRouteAfterLogout();
      await authLogout();
      popAlert(appContent.alerts.loggedOutSuccess, AlertType.SUCCESS);
    } catch (e) {
      popError(e.message);
    }
  };

  return <MarkdownPageLayout
    logo="lg"
    markdown={appContent.inGame.joinGame.markdown}
    isLoading={!hasFinishedCheck}
    hideChildrenWhenLoading
    usePrimaryGame
  >
    <LoadingSpinner isLoading={isLoading}>
      {showCreateTeamButton && (
        <Button block onClick={() => setShowCreateTeam(true)}>
          {appContent.inGame.joinGame.createTeamButtonText}
        </Button>
      )}
      <Button block onClick={() => setShowJoinTeam(true)}>
        {appContent.inGame.joinGame.joinTeamButtonText}
      </Button>
      <TeamNameModal
        className="modal--below-alerts"
        scenario="create"
        show={showCreateTeam}
        onHide={() => setShowCreateTeam(false)}
        onFinish={handleCreateTeam}
        isLoading={isLoading}
      />
      <JoinTeamModal
        className="modal--below-alerts"
        show={showJoinTeam}
        onHide={() => {
          setShowJoinTeam(false);
        }}
        onFinish={handleJoinTeam}
        isLoading={isLoading}
      />
      <InviteInstructionsModal
        className="modal--below-alerts"
        inviteCode={createdGameInviteCode}
        show={showInviteInstructions}
        onFinish={() => navigateToGame(gameId)}
      />
      <LogoutMessage
        className="mt-4"
        onLogout={handleLogout}
      />
    </LoadingSpinner>
    <ApplyCustomGameTheme usePrimaryGame/>
  </MarkdownPageLayout>;
};

export default JoinGamePage;
