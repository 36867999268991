import {useContext, useEffect} from "react";
import {getCurrentUserID} from "../services/firebase";
import {compareTimestamps} from "../utilities/dates";
import {AlertType} from "../components/providers/app-alerts/AppAlerts";
import {usePreviousValue} from "./usePreviousValue";
import useArrivedOnPageTimestamp from "./useArrivedOnPageTimestamp";
import {AppAlertsContext} from "../context/app-alerts-context";
import GameContext from "../context/game-context";

export default function useNotifyOnNewTeamMember() {
  const {team} = useContext(GameContext);
  const arrivedAt = useArrivedOnPageTimestamp();
  const previousTeamMembers = usePreviousValue(team?.orderedUsers);
  const {popAlert} = useContext(AppAlertsContext);

  useEffect(() => {
    if (
      team?.orderedUsers.length >= 1 &&
      team?.orderedUsers.length !== previousTeamMembers?.length
    ) {
      const [lastTeamMember] = team?.orderedUsers.slice(-1);

      // Only show joined if player joined after user arrived and isn't them
      if (
        getCurrentUserID() !== lastTeamMember.id &&
        compareTimestamps(lastTeamMember.addedAt, arrivedAt) <= 0
      ) {
        popAlert(`${lastTeamMember.name} joined your team`, AlertType.SUCCESS);
      }
    }
  });
}
